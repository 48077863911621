import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ContactUsForm from "./../components/ContactUsForm";
import MiscSocialSmall from "../components/icons/MiscSocialSmall";
// import ContactUsMap from "./../components/ContactUsMap";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";

const ContactUsPage = () => {
  const contactUsQuery = useStaticQuery(graphql`
    {
      craftContactUsContactUsEntry {
        title
        richTextField
        callToAction
      }
      craftSiteSettingsGlobalSet {
        globalEmailAddress
        globalPhoneNumber
      }
    }
  `);
  const {
    title,
    richTextField,
    callToAction,
  } = contactUsQuery.craftContactUsContactUsEntry;
  const {
    globalEmailAddress,
    globalPhoneNumber,
  } = contactUsQuery.craftSiteSettingsGlobalSet;
  // console.log(globalEmailAddress, globalPhoneNumber);
  return (
    <Layout
      backgroundColor={"var(--green)"}
      headerColor={"var(--black)"}
      headerBlack
      title={title}
      SEODescription={richTextField}
    >
      <StandalonePageLayout>
        <h1>{title}</h1>
        <div>
          <WhiteBox>
            <div dangerouslySetInnerHTML={{ __html: richTextField }} />
          </WhiteBox>
          <ContactUsForm callToAction={callToAction} />
          <WhiteBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>
                <a
                  href={`mailto:${globalEmailAddress}`}
                  style={{ color: "var(--black)" }}
                >
                  {globalEmailAddress}
                </a>
                <br />
                <a
                  href={`tel:${globalPhoneNumber}`}
                  style={{ color: "var(--black)" }}
                >
                  {globalPhoneNumber}
                </a>
              </p>
              <MiscSocialSmall color={"var(--black)"} useInstagram />
            </div>
          </WhiteBox>
          <div style={{ height: "var(--outerMargin)" }} />
        </div>
      </StandalonePageLayout>
    </Layout>
  );
};

export default ContactUsPage;
